// const HTTP_ACTION = "HTTP_ACTION";

export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_FAIL = 'GET_QUESTION_FAIL';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_FAIL = 'GET_TOKEN_FAIL';
export const GET_USER = 'GET_USER';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS'

//Authenticate
export const IS_AUTH = 'IS_AUTH';
export const IS_AUTH_FAIL = 'IS_AUTH_FAIL';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILED = 'GET_OTP_FAILED';

//user
export const GET_OFFSHORE_ACCOUNT = 'GET_OFFSHORE_ACCOUNT';
export const GET_SAVINGS_ACCOUNT = 'GET_SAVINGS_ACCOUNT';
export const GET_CURRENT_ACCOUNT = 'GET_CURRENT_ACCOUNT'
export const GET_OFFSHORE_ACCOUNT_FAILED = 'GET_OFFSHORE_ACCOUNT_FAILED';
export const GET_SAVINGS_ACCOUNT_FAILED = 'GET_SAVINGS_ACCOUNT_FAILED';
export const GET_CURRENT_ACCOUNT_FAILED = 'GET_CURRENT_ACCOUNT_FAILED';

//transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED';

//account types
export const GET_ACCOUNT_TYPES = 'GET_ACCOUNT_TYPES';
export const GET_ACCOUNT_TYPES_FAILED = 'GET_ACCOUNT_TYPES_FAILED';

//user acc
export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';

//Transfers
export const MAKE_TRANSFER = 'MAKE_TRANSFER';
export const MAKE_TRANSFER_FAILED = 'MAKE_TRANSFER_FAILED';

//Upload Pictures
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const UPLOAD_PICTURE_FAILED ='UPLOAD_PICTURE_FAILED';


//Register
export const SAVE_FIRST_STEP = 'SAVE_FIRST_STEP';
export const SAVE_SECOND_STEP = 'SAVE_SECOND_STEP';